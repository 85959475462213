import * as Sentry from '@sentry/nuxt';

if (import.meta.env.DEV === false) {
  Sentry.init({
    dsn: 'https://ac6e78aed99d07e5cb58cab0c05b8446@o47144.ingest.us.sentry.io/4507849157705728',

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
